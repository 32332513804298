
import { defineComponent, createVNode } from "vue";
import Base from "@/components/template/Base.vue";
import CarryOut from "@/components/template/CarryOut.vue";
import Done from "@/components/template/Done.vue";
import {
  getDetails,
  addMode,
  updateMode,
  copyMode,
} from "@/api/template/api.js";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
// import { Modal } from "ant-design-vue";
export default defineComponent({
  components: {
    Base,
    CarryOut,
    Done,
    ExclamationCircleOutlined,
  },
  data() {
    return {
      spinning: true,
      noBottomBtn: false,
      current: 0, //当前步骤条
      currentDone: 0, //已完成到第几步
      steps: [
        {
          title: "基础信息",
          num: 0,
        },
        {
          title: "执行内容配置",
          num: 1,
        },
        {
          title: "完成",
          num: 2,
        },
      ],
      //从路由传过来的id获取到的本条数据
      formState: {
        taskPackage: {
          taskSub: [{}],
        },
      },
      keepFormState: JSON.stringify({
        taskPackage: {
          taskSub: [{}],
        },
      }),
    };
  },
  methods: {
    changeSteps(current) {},
    async getData(id: any = false, cb: any = false) {
      if (!id) {
        id = this.$route.params.id;
      }
      let res: any = await getDetails(id);
      if (!res.data.data.taskPackage) {
        res.data.data.taskPackage = {};
        res.data.data.taskPackage.taskSub = [{}];
      }
      this.formState = res.data.data;
      // if (this.$route.path.includes("/templateApp")) {
      //    this.formState =
      // }
      this.keepFormState = JSON.stringify(res.data.data);
      (this as any).$store.commit("template/templateFormState", this.formState);
      if (res.data.data.taskTypeCode == null) {
        this.currentDone = 0;
      } else {
        this.currentDone = 1;
        // } else if (res.data.data.taskStatus != "编辑中") {
        //   this.currentDone = 2;
      }
      if (this.$route.path.includes("/details")) {
        this.currentDone = 2;
      }
      if (cb) {
        cb();
      }
    },
    // 点击完成
    async save() {
      (this as any).$refs.formRef.validate().then(() => {
        // this.keepFormState = JSON.stringify(this.formState);
        if (this.current == 1) {
          this.currentDone = 2;
          this.current++;
        }
        if (this.current == 0) {
          if (this.$route.params.id === "new") {
            // 新建
            let typeName = "";
            switch ((this as any).formState.taskTypeCode) {
              case "CTT000EXPANDTASK":
                typeName = "拓店任务";
                break;
              case "CTT000ADDSTORE":
                typeName = "新增店铺";
                break;
            }
            // let typeName =
            //   (this as any).formState.taskTypeCode === "CTT000EXPANDTASK"
            //     ? "拓店任务"
            //     : "新增店铺";
            addMode({
              id: (this as any).formState.id,
              typeName: typeName,
              taskTypeCode: (this as any).formState.taskTypeCode,
              taskTitle: (this as any).formState.taskTitle,
              memo: (this as any).formState.memo,
            }).then((res) => {
              if (res.data.success) {
                let path = "/template";
                if (this.$route.path.includes("templateApp")) {
                  path = "/templateApp";
                  this.$router.push(`${path}/created1/${res.data.data.id}`);
                } else {
                  this.$router.push(`${path}/created/${res.data.data.id}`);
                }
                setTimeout(() => {
                  this.getData(this.$route.params.id, () => {
                    this.currentDone = 1;
                    this.current++;
                  });
                }, 0);
              }
            });
          } else {
            // 编辑
            // CTT000EXPANDTASK 拓店任务
            let typeName = "";
            switch ((this as any).formState.taskTypeCode) {
              case "CTT000EXPANDTASK":
                typeName = "拓店任务";
                break;
              case "CTT000ADDSTORE":
                typeName = "新增店铺";
                break;
            }
            // let typeName =
            // (this as any).formState.taskTypeCode === "CTT000EXPANDTASK"
            //   ? "拓店任务"
            //   : "新增店铺";
            (this as any).formState.typeName = typeName;
            updateMode({
              id: (this as any).formState.id,
              taskTitle: (this as any).formState.taskTitle,
              memo: (this as any).formState.memo,
            }).then((res) => {
              if (res.data.success) {
                // this.gotoCreated(res.data.data.id);
                this.currentDone = 1;
                this.current++;
              }
            });
          }
        }
      });
    },
    gotoCreated(id) {
      let path = "/template";
      if (this.$route.path.includes("templateApp")) {
        path = "/templateApp";
        this.$router.push(`${path}/created1/${id}`);
      } else {
        this.$router.push(`${path}/created/${id}`);
      }
    },
    cancel() {
      let path = "/template";
      if (this.$route.path.includes("templateApp")) {
        path = "/templateApp/list";
      }
      this.$router.push(path);
      // let that = this;
      // // console.log(JSON.stringify(this.formState)===this.keepFormState);
      // if (
      //   this.$route.path.includes("/details") ||
      //   this.current !== 0 ||
      //   JSON.stringify(this.formState) === this.keepFormState
      // ) {
      //   that.$router.push("/template");
      // } else {
      //   Modal.confirm({
      //     title: "提示",
      //     icon: () => createVNode(ExclamationCircleOutlined),
      //     content: "离开此页面未保存的信息将会消失!",
      //     cancelText: "取消",
      //     okText: "离开",
      //     onOk() {
      //       that.$router.push("/template");
      //     },
      //   });
      // }
    },
    toCreated() {
      copyMode(this.$route.params.id).then((res: any) => {
        if (res.data.success) {
          this.formState = res.data.data;
          this.keepFormState = JSON.stringify(res.data.data);
          (this as any).$store.commit(
            "template/templateFormState",
            this.formState
          );
          this.currentDone = 0;
          this.current = 0;
          let path = "/template";
          if (this.$route.path.includes("templateApp")) {
            path = "/templateApp";
            (this as any).$router.push(`${path}/created1/${res.data.data.id}`);
          } else {
            (this as any).$router.push(`/template/created/${res.data.data.id}`);
          }
          setTimeout(() => {
            this.getData();
          }, 0);
        }
      });
    },
  },
  mounted() {
    if (this.$route.params.id != "new") {
      this.getData();
    } else {
      (this as any).$store.commit("template/templateFormState", this.formState);
    }
  },
  watch: {
    current(val) {
      if (val === 1) {
        this.spinning = true;
        setTimeout(() => {
          (this as any).$refs.CarryOut.init().then((res) => {
            if (res === "notUpdate") {
              this.spinning = false;
            }
            if (res === "update") {
              this.getData(this.$route.params.id, () => {
                this.spinning = false;
              });
            }
          });
        }, 0);
      }
    },
  },
});
