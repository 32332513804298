import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8cbbf6e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "stepsBox" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { style: {"padding":"15px 23% 0"} }
const _hoisted_7 = {
  key: 0,
  class: "submitBox"
}
const _hoisted_8 = {
  key: 1,
  class: "submitBox"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_step = _resolveComponent("a-step")!
  const _component_a_steps = _resolveComponent("a-steps")!
  const _component_Base = _resolveComponent("Base")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_CarryOut = _resolveComponent("CarryOut")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_Done = _resolveComponent("Done")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createVNode(_component_a_steps, {
          current: _ctx.current,
          "onUpdate:current": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.current) = $event)),
          class: "steps",
          onChange: _ctx.changeSteps
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (item) => {
              return (_openBlock(), _createBlock(_component_a_step, {
                key: item.title,
                title: item.title,
                disabled: _ctx.currentDone < item.num
              }, null, 8, ["title", "disabled"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["current", "onChange"])
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["contentBox", _ctx.noBottomBtn || _ctx.current === 2 ? 'noBottomBtn ' : ''])
    }, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.current === 0)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, "基础信息配置"))
          : _createCommentVNode("", true),
        (_ctx.current === 1)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, "执行内容配置"))
          : _createCommentVNode("", true),
        (_ctx.current === 2)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_a_form, {
          model: _ctx.formState,
          ref: "formRef",
          "label-col": { style: { width: '110px' } },
          class: "a_form"
        }, {
          default: _withCtx(() => [
            (_ctx.current === 0)
              ? (_openBlock(), _createBlock(_component_Base, {
                  key: 0,
                  formState: _ctx.formState,
                  isEdit: _ctx.$route.path.includes('created')
                }, null, 8, ["formState", "isEdit"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["model"]),
        (_ctx.current === 1)
          ? (_openBlock(), _createBlock(_component_a_spin, {
              key: 0,
              spinning: _ctx.spinning,
              tip: "初始化采集项..."
            }, {
              default: _withCtx(() => [
                _createVNode(_component_CarryOut, {
                  formState: _ctx.formState.taskPackage.taskSub[0],
                  problemKey: "collectItems",
                  optionKey: "options",
                  taskPackageKey: "taskPackage",
                  taskSubKey: "taskSub",
                  frequency: 0,
                  isMust: 0,
                  isEdit: _ctx.$route.path.includes('created'),
                  ref: "CarryOut"
                }, null, 8, ["formState", "isEdit"])
              ]),
              _: 1
            }, 8, ["spinning"]))
          : _createCommentVNode("", true),
        (_ctx.current === 2)
          ? (_openBlock(), _createBlock(_component_Done, {
              key: 1,
              formState: _ctx.formState
            }, null, 8, ["formState"]))
          : _createCommentVNode("", true)
      ])
    ], 2),
    (!_ctx.noBottomBtn && _ctx.current !== 2 && !_ctx.$route.path.includes('/details'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          (_ctx.current === 0)
            ? (_openBlock(), _createBlock(_component_a_button, {
                key: 0,
                style: {"margin-right":"20px","height":"40px","width":"90px"},
                onClick: _ctx.cancel
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" 返回 ")
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true),
          (_ctx.current === 1)
            ? (_openBlock(), _createBlock(_component_a_button, {
                key: 1,
                style: {"margin-right":"20px","height":"40px","width":"90px"},
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.current--))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" 上一步 ")
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.current === 0 || _ctx.current === 1)
            ? (_openBlock(), _createBlock(_component_a_button, {
                key: 2,
                type: "primary",
                style: {"margin-right":"20px","height":"40px","min-width":"90px"},
                onClick: _ctx.save
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" 保存并下一步 ")
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.$route.path.includes('/details'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_a_button, {
            style: {"margin-right":"20px","height":"40px","width":"90px"},
            onClick: _ctx.cancel
          }, {
            default: _withCtx(() => [
              _createTextVNode(" 返回 ")
            ]),
            _: 1
          }, 8, ["onClick"]),
          (_ctx.current === 0 || _ctx.current === 1)
            ? (_openBlock(), _createBlock(_component_a_button, {
                key: 0,
                type: "primary",
                style: {"margin-right":"20px","height":"40px","width":"100px"},
                onClick: _ctx.toCreated
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" 快速创建 ")
                ]),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}